const data = [{
    title: 'Smart Unlock',
    subtitle: 'Won 3rd. place in Robot Innovation Design Competition (Peking University)',
    link: 'https://github.com/anmol1707/SmartUnlock',
    image: '/images/projects/SmartUnlock.png',
    date: '2019-07-30',
    desc: 'A mobile app built on React Native that establishes a secure connection with ' +
        'Arduino-based smart doors, sends a notification to users to open the door when they approach it ' +
        'and closes it when the user passes through the door. ' +
        'Awarded 3rd prize at Robot Innovation Design Competition, Peking University.'
}, {
    title: 'Big Two',
    subtitle: 'Object Oriented Programming',
    link: 'https://github.com/anmol1707/BigTwo',
    image: '/images/projects/BigTwo.png',
    date: '2019-04-30',
    desc: 'A card game build using Java Swing (GUI) that supports network-enabled multiplayer gameplay ' +
        'through multithreading and java socket programming over TCP/IP.'
}, {
    title: 'SimplyBookJS',
    subtitle: 'A kickstarter funded potato powered weather balloon.',
    link: 'https://github.com/anmol1707/SimplyBookJs',
    image: '/images/projects/SimplyBookJS.png',
    date: '2018-11-30',
    desc: 'A movie booking system developed using Express.js, React.js, MongoDB and Material UI. '
        + 'Key Features include user login and registration, movie hall and time selection and ' +
        'viewing purchase history.'
}];

export default data;
