const degrees = [
  {
    school: 'University of Hong Kong',
    degree: 'Bachelor of Engineering - Computer Science',
    link: 'https://www.cs.hku.hk/',
    year: "Sept 2017 - June 2022",
  },
  {
    school: 'Peking University',
    degree: 'Summer Exchange',
    link: 'http://globex.coe.pku.edu.cn/',
    year: "July 2019",
  },
  {
    school: 'Korea Advanced Institute of Science and Technology',
    degree: 'Summer Exchange',
    link: 'http://summer.kaist.ac.kr/',
    year: "July 2018",
  },
];

export default degrees;
