import firebase from "firebase";
import 'firebase/analytics';

let firebaseConfig = {
    apiKey: "AIzaSyAP5zwofSxv4stn1d9zxg20QWZnfvBowgs",
    authDomain: "personal-website-fc187.firebaseapp.com",
    databaseURL: "https://personal-website-fc187.firebaseio.com",
    projectId: "personal-website-fc187",
    storageBucket: "",
    messagingSenderId: "508925935758",
    appId: "1:508925935758:web:d468f6586331df3cf889df",
    measurementId: "G-2J4FHDKNCZ"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
