import axios from "axios";
import firebase from "firebase";
import * as ReactGA from 'react-ga';

const API = "https://us-central1-personal-website-fc187.cloudfunctions.net/";
// const API = "http://localhost:5000/personal-website-fc187/us-central1/";

export async function pageLevelTracking(pageName, isOpen) {
    let companyId = window.localStorage.getItem("id");
    firebase.analytics().setCurrentScreen(pageName);
    firebase.analytics().logEvent(isOpen ? "Page Opened" : "Page Closed", {
        company_id: companyId,
        screen_name: pageName
    });
    ReactGA.pageview(pageName);
    ReactGA.event({
        category: isOpen ? "Page Open" : "Page Close",
        action: companyId,
        label: pageName
    });

    let body = {
        id: companyId,
        pageName: pageName + (isOpen ? "_Opened" : "_Closed")
    };

    try {
        await trackLocation();
    } catch (e) {
    } finally {
        setTimeout(() => {
            let api = API + "trackWebsiteOpen";
            axios.post(api, {...body});
        }, isOpen ? 1000 : 0);
    }
}

async function trackLocation() {
    let companyId = window.localStorage.getItem("id");
    let api = API + "trackLocation";

    let body = {
        id: companyId
    };

    try {
        const ipTrackingData = await axios.get("https://ipinfo.io?token=caaad7f8acabdf");
        body["ipTrackingData"] = ipTrackingData.data;
    } catch (e) {
    } finally {
        if (body["ipTrackingData"] == null) {
            body["ipTrackingData"] = new Date().toString();
        }
    }
    await axios.post(api, {...body});
}

export function sectionLevelTracking(pageName, sectionName) {
    let companyId = window.localStorage.getItem("id");
    firebase.analytics().logEvent("Section Opened", {
        company_id: companyId,
        main_section: pageName,
        sub_section: sectionName
    });
    ReactGA.event({
        category: "Section Open",
        action: companyId,
        label: pageName + "/" + sectionName
    });

    let body = {
        id: companyId,
        pageName: pageName,
        sectionName: sectionName
    };
    let api = API + "trackSectionOpen";
    axios.post(api, {...body});
}
