import React from 'react';
import Helmet from 'react-helmet';

import Main from '../layouts/Main';

import Education from '../components/Resume/Education';
import Experience from '../components/Resume/Experience';
import Skills from '../components/Resume/Skills';
import Courses from '../components/Resume/Courses';

import courses from '../data/resume/courses';
import degrees from '../data/resume/degrees';
import positions from '../data/resume/positions';
import {skills, categories} from '../data/resume/skills';
import {pageLevelTracking, sectionLevelTracking} from "../../backend_api";

const sections = [
    'Education',
    'Experience',
    'Skills',
    'Courses',
];

export default class Resume extends React.Component {
    constructor(props) {
        super(props);
        pageLevelTracking( "Resume", true);
    }

    componentWillUnmount() {
        pageLevelTracking("Resume", false);
    }

    render() {
        return (
            <Main>
                <Helmet title="Resume"/>
                <article className="post" id="resume">
                    <header>
                        <div className="title">
                            <h2>Resume</h2>
                            <div className="link-container">
                                {sections.map((sec) => (
                                    <h4 key={sec}>
                                        <a onClick={() => {
                                            sectionLevelTracking("Resume", sec);
                                        }}
                                           href={`#${sec.toLowerCase()}`}>{sec}</a>
                                    </h4>))}
                            </div>
                        </div>
                    </header>
                    <Education data={degrees}/>
                    <Experience data={positions}/>
                    <Skills skills={skills} categories={categories}/>
                    <Courses data={courses}/>
                </article>
            </Main>
        );
    }
}
