export const categories = [
    {
        name: 'Languages',
        color: '#515dd4',
    },
    {
        name: 'Java',
        color: '#37b1f5'
    }, {
        name: 'Javascript',
        color: '#d75858',
    }, {
        name: 'Databases',
        color: '#3896e2',
    }, {
        name: 'Client-side Development',
        color: '#cc7b94',
    },
    {
        name: 'Server-side Development',
        color: '#747fff'
    }, {
        name: 'Cloud Computing',
        color: '#64cb7b'
    }, {
        name: 'Tools',
        color: '#40494e',
    }
];

export const skills = [
    {
        title: 'Java',
        compentency: 5,
        category: ['Server-side Development', 'Languages', 'Java'],
    },
    {
        title: 'JUnit',
        compentency: 4,
        category: ['Java'],
    },
    {
        title: 'Mockito',
        compentency: 3,
        category: ['Java'],
    },
    {
        title: 'Spring',
        compentency: 3,
        category: ['Java'],
    },
    {
        title: 'Javascript',
        compentency: 5,
        category: ['Languages', 'Javascript', 'Client-side Development'],
    },
    {
        title: 'Typescript',
        compentency: 5,
        category: ['Client-side Development', 'Server-side Development', 'Languages', 'Javascript'],
    },
    {
        title: 'Node.js',
        compentency: 5,
        category: ['Server-side Development', 'Javascript'],
    },
    {
        title: 'Express.js',
        compentency: 4,
        category: ['Server-side Development', 'Javascript'],
    },
    {
        title: 'React.js / React Native',
        compentency: 5,
        category: ['Client-side Development', 'Javascript'],
    },
    {
        title: 'Redux',
        compentency: 4,
        category: ['Javascript', 'Client-side Development'],
    },
    {
        title: 'Bash',
        compentency: 3,
        category: ['Tools'],
    },
    {
        title: 'MongoDB',
        compentency: 4,
        category: ['Databases'],
    },
    {
        title: 'Amazon DynamoDB',
        compentency: 4,
        category: ['Cloud Computing', 'Databases'],
    },
    {
        title: 'Google Firebase',
        compentency: 5,
        category: ['Databases'],
    },
    {
        title: 'MySql',
        compentency: 2,
        category: ['Databases'],
    },
    {
        title: 'Git',
        compentency: 3,
        category: ['Tools'],
    },
    {
        title: 'Google Cloud Compute',
        compentency: 3,
        category: ['Cloud Computing'],
    },
    {
        title: 'Amazon EC2',
        compentency: 3,
        category: ['Cloud Computing'],
    },
    {
        title: 'Google Cloud Storage',
        compentency: 3,
        category: ['Cloud Computing'],
    },
    {
        title: 'Amazon S3',
        compentency: 3,
        category: ['Cloud Computing'],
    },
    {
        title: 'HTML',
        compentency: 3,
        category: ['Client-side Development', 'Languages'],
    },
    {
        title: 'CSS',
        compentency: 3,
        category: ['Client-side Development', 'Languages'],
    },
    {
        title: 'Python',
        compentency: 2,
        category: ['Server-side Development', 'Languages'],

    },
    {
        title: 'C++',
        compentency: 2,
        category: ['Languages'],
    },
    {
        title: 'Jira',
        compentency: 3,
        category: ['Tools']
    },
    {
        title: 'BitBucket',
        compentency: 4,
        category: ['Tools']
    },
    {
        title: 'MailChimp',
        compentency: 3,
        category: ['Tools']
    }
];
