import React from 'react';
import {Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import data from '../../data/contact';
import {BASE_PATH} from "../../../constants";

const Nav = () => (
    <section id="sidebar">
        <section id="intro">
            <Link to="/" className="logo">
                <img src={`${BASE_PATH}/images/me.jpg`} alt=""/>
            </Link>
            <header>
                <h2>Anmol Gupta</h2>
                <p><a href="mailto:anmol17@connect.hku.hk">anmol17@connect.hku.hk</a></p>
            </header>
        </section>

        <section id="footer">
            <ul className="icons">
                {data.map((s) => (
                    <li key={s.label}>
                        <a target={"_blank"} href={s.link}>
                            <FontAwesomeIcon icon={s.icon}/>
                        </a>
                    </li>
                ))}
            </ul>
        </section>
    </section>
);

export default Nav;
