const positions = [
    {
        company: "Facebook",
        position: "Incoming Software Engineering Intern - Facebook Artificial Intelligence Research",
        link: "https://www.facebook.com/",
        daterange: "June 2021 - September 2021",
        points: [
            "Will be working with the FAIR team to develop and deploy state-of-the-art machine translation systems."
        ]
    },
    {
        company: "Apple",
        position: "Machine Hearing Intern - Siri Understanding",
        link: "https://www.apple.com/",
        daterange: "January 2021 - May 2021",
        points: [
            "Built a unified model, capable of performing keyword spotting (KWS) and speaker recognition, that outperformed the best joint model approach in literature by 20% in KWS and achieving parity in speaker recognition.",
            "Wrote a research paper as the first author to describe the novel approach used in developing the joint model, which will be submitted to the ICASSP 2021 conference.",
            "Followed a research paper and implemented a model that supports KWS for any given keyword, which will serve as a baseline model for future research."
        ]
    },
    {
        company: "Apple",
        position: "Software Engineering Intern - Siri Understanding",
        link: "https://www.apple.com/",
        daterange: "June 2020 - September 2020",
        points: [
            "Developed a cross-attention decoder as part of the Transformer architecture which improved “Hey Siri” keyword detection by reducing false rejection rates up to 12%.",
            "Designed a new training infrastructure on PyTorch for various machine hearing projects, which saves up to 200 GPU hours and 800 CPU hours of compute resources for every training job."
        ]
    },
    {
        company: "Google",
        position: "Software Engineering Intern - Platforms and Ecosystems",
        link: "https://www.google.com/",
        daterange: "March 2020 - May 2020",
        points: [
            "Developed infrastructure in Android OS to record and export states of Input Method Editor (IME), which increased engineering productivity by reducing testing & debugging time by up to 70%.",
            "Built a tool to efficiently analyse the exported states, which facilitates easy identification of bugs in IME and reduces behaviour inconsistencies and code rollbacks by up to 25%.",
            "Worked with Java and Protocol Buffers for Android, and with Vue.js and Material UI for the data visualization tool."
        ]
    },
    {
        company: 'Jump Rope For Good',
        position: 'Software Engineering Intern',
        link: 'https://www.roju.app/',
        daterange: 'January 2020 - February 2020',
        points: [
            "Developed a cross platform mobile app that allows users to record and upload videos, like and comment on news feed posts and make in-app purchases.",
            "Built a server using Spring Frameworks and Java, with PostgreSQL as database, and deployed it on AWS Elastic Beanstalk.",
            "Implemented real-time notifications using Web-Sockets and Database Event Triggers."
        ],
    },
    {
        company: 'Planto',
        position: 'Software Engineering Intern',
        link: 'https://www.planto.hk/en/',
        daterange: 'September 2018 - September 2019',
        points: [
            'Delivered highly scalable code using efficient algorithms and advanced Java collections; Followed the agile SDLC model.',
            'Developed emails/notifications pipeline that improved user retention by 40% and reduced execution time by 12 hours.',
            'Built website scrapers using Selenium; Created multiple features for the mobile app used by more than 100,000 users.',
            'Practiced test-driven development and Continuous Integration; Worked with Java, Spring, JUnit, React Native and Redux.'
        ]
    }, {
        company: 'Weava Tools',
        position: 'Mobile Application Developer Intern',
        link: 'https://weavatools.com',
        daterange: 'December 2018 - May 2019',
        points: [
            'Developed a cross-platform app that allows users to highlight text within the in-app browser and PDF renderer.',
            'Integrated the in-app purchases API (supporting Apple Pay and Google Pay) for payments and Google/Facebook login for easy sign up.',
            'Improved users’ efficiency by up to 60% when researching for essays, articles, research papers and exams.',
            'Implemented the mobile app using React Native, Typescript, Redux and Firebase.',
        ]
    },
    {
        company: "University of Hong Kong",
        position: "Teaching Assistant",
        link: "https://www.cs.hku.hk/index.php/programmes/course-offered?infile=2019/comp1117.html",
        daterange: "2018 - 2020",
        points: [
            "COMP1117 – Computer Programming: Helped 150 students understand and implement algorithms in python.",
            "COMP2396 – Object Oriented Programming: Teaching the object-oriented principles in programming and their application to solve real world problems to 100+ students."
        ]
    }
];

export default positions;
