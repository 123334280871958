import React from 'react';
import Helmet from 'react-helmet';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Main from '../layouts/Main';

import data from '../data/contact';
import {pageLevelTracking} from "../../backend_api";

const validateText = (text) => {
    const re = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))$/;
    return re.test(text) || text.length === 0;
};

const messages = [
    'hi',
    'hello',
    'hola',
    'you-can-email-me-at-literally-anything! Really',
    'well, not anything. But most things',
    'like-this',
    'or-this',
    'but not this :(  ',
    'you.can.also.email.me.with.specific.topics.like',
    'just-saying-hi',
    'please-work-for-us',
    'help',
    'admin',
    'or-I-really-like-your-website',
    'I\'ll-stop-distracting-you-now',
    'thanks',
];

export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idx: 0,
            message: messages[0],
            char: messages[0].length,
            isActive: true
        };

        this.triggerInterval = this.triggerInterval.bind(this);

        pageLevelTracking("Contact", true);
    }

    componentWillUnmount() {
        pageLevelTracking("Contact", false);
    }

    triggerInterval() {
        const hold = 50; // ticks to wait after message is complete before rendering next message
        const delay = 50; // tick length in mS

        const id = setInterval(() => {
            let newIdx = this.state.idx;
            let newChar = this.state.char;
            if (this.state.char - hold >= messages[this.state.idx].length) {
                newIdx += 1;
                newChar = 1;
            }
            if (newIdx === messages.length) {
                this.setState({
                    isActive: false
                })
            } else {
                this.setState({
                    message: messages[newIdx].slice(0, newChar),
                    idx: newIdx,
                    char: newChar + 1
                });
            }

            if (!this.state.isActive) {
                clearInterval(id);
            }
        }, delay);
    }

    componentDidMount() {
        this.triggerInterval();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.isActive && this.state.isActive) {
            this.triggerInterval();
        }
    }

    render() {
        return (
            <Main>
                <Helmet title="Contact"/>
                <article className="post" id="contact">
                    <header>
                        <div className="title">
                            <h2>Contact</h2>
                        </div>
                    </header>
                    <div className="email-at">
                        <p>Feel free to get in touch. You can email me at: </p>
                        <div
                            className="inline-container"
                            onMouseEnter={() => this.setState({
                                isActive: true
                            })}
                            onMouseLeave={() => (this.state.idx < messages.length) && this.setState({
                                isActive: true
                            })}>
                            <a href={"mailto:anmol17@connect.hku.hk"}>
                                <span>{"anmol17@connect.hku.hk"}</span>
                            </a>
                        </div>
                        <div
                            className="inline-container"
                            style={validateText(this.state.message) ? {} : {color: 'red'}}
                            onMouseEnter={() => this.setState({
                                isActive: false
                            })}
                            onMouseLeave={() => (this.state.idx < messages.length) && this.setState({
                                isActive: true
                            })}>
                            <a href={validateText(this.state.message) ? `mailto:${this.state.message}@anmolgupta.dev` : ''}>
                                <span>{this.state.message}</span>
                                <span>@anmolgupta.dev</span>
                            </a>
                        </div>
                    </div>
                    <ul className="icons">
                        {data.map((s) => (
                            <li key={s.label}>
                                <a target={"_blank"} href={s.link}>
                                    <FontAwesomeIcon icon={s.icon}/>
                                </a>
                            </li>
                        ))}
                    </ul>
                </article>
            </Main>
        );
    }
}
