const courses = [
    {
        title: 'Introduction to Relational Databases',
        number: 'CPSC 304',
        link: 'https://courses.students.ubc.ca/cs/courseschedule?pname=subjarea&tname=subj-course&dept=CPSC&course=304',
        univerity: 'University of British Columbia',
    }, {
        title: 'Discrete Mathematics',
        number: 'CS 204',
        link: 'http://nlpcl.kaist.ac.kr/~cs204/',
        univerity: 'KAIST University',
    }, {
        title: 'Object Oriented Programming and Java',
        number: 'COMP 2396',
        link: 'https://www.cs.hku.hk/index.php/programmes/course-offered?infile=2019/comp2396.html',
        univerity: 'HKU',
    }, {
        title: 'Functional Programming (Haskell)',
        number: 'COMP 3258',
        link: 'https://www.cs.hku.hk/index.php/programmes/course-offered?infile=2019/comp3258.html',
        univerity: 'HKU',
    }, {
        title: 'Data Structures and Algorithms',
        number: 'COMP 2119',
        link: 'https://www.cs.hku.hk/index.php/programmes/course-offered?infile=2019/comp2119.html',
        univerity: 'HKU',
    }, {
        title: 'Computer Organization',
        number: 'COMP 2120',
        link: 'https://www.cs.hku.hk/index.php/programmes/course-offered?infile=2019/comp2120.html',
        univerity: 'HKU',
    }, {
        title: 'Modern Technologies in World Wide Web',
        number: 'COMP 3322',
        link: 'https://www.cs.hku.hk/index.php/programmes/course-offered?infile=2019/comp3322.html',
        univerity: 'HKU',
    }, {
        title: 'Programming Technologies and Tools',
        number: 'COMP 2123',
        link: 'https://www.cs.hku.hk/index.php/programmes/course-offered?infile=2019/comp2123.html',
        univerity: 'HKU',
    }
];

export default courses;
