import React from 'react';
import {Link} from 'react-router-dom';
import Main from '../layouts/Main';
import {pageLevelTracking} from "../../backend_api";

export default class Index extends React.Component {
    constructor(props) {
        super(props);

        pageLevelTracking("Home", true);
    }

    componentWillUnmount() {
        pageLevelTracking("Home", false);
    }

    render() {
        return (
            <Main>
                <article className="post" id="index">
                    <header>
                        <div className="title">
                            <h2>About Me</h2>
                        </div>
                    </header>
                    <p>I am a Computer Science Junior at <a target={"_blank"} href="https://cs.hku.hk/">The Univeristy of Hong
                            Kong</a>.
                        A passionate developer with strong technical skillset and diverse work
                        experience, I love building robust and scalable applications that solve
                        day-to-day problems.
                    </p>
                    <p>Being an avid soccer fan, I never miss a game when <a target={"_blank"} href="https://www.realmadrid.com/en/">Real
                            Madrid</a> is playing. I love experiencing different countries and cultures and have
                        travelled to South Korea, Philippines, China, Canada and The United States
                        for fun, work and studies.</p>
                    <p>Please feel free to check out my {' '}
                        <Link to="/resume">resume</Link>, {' '}
                        <Link to="/projects">projects</Link>, {' '}
                        or <Link to="/contact">contact</Link> me.
                    </p>
                </article>
            </Main>
        );
    }
};
