import React from 'react';
import Helmet from 'react-helmet';

import Main from '../layouts/Main';

import Cell from '../components/Projects/Cell';
import data from '../data/projects';
import {pageLevelTracking} from "../../backend_api";

export default class Projects extends React.Component {
    constructor(props) {
        super(props);

        pageLevelTracking( "Projects", true);
    }

    componentWillUnmount() {
        pageLevelTracking("Projects", false);
    }

    render() {
        return (
            <Main>
                <Helmet title="Projects"/>
                <article className="post" id="projects">
                    <header>
                        <div className="title">
                            <h2>Projects</h2>
                            <p>A selection of projects that I&apos;m not too ashamed of</p>
                        </div>
                    </header>
                    {data.map((project) => (
                        <Cell
                            data={project}
                            key={project.title}
                        />
                    ))}
                </article>
            </Main>
        );
    }
}
