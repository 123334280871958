import React from 'react';
import './app/static/css/main.scss';
import Index from "./app/views/Index";
import Projects from "./app/views/Projects";
import Contact from "./app/views/Contact";
import Resume from "./app/views/Resume";
import NotFound from "./app/views/NotFound";
import firebase from "./firebase";
import * as ReactGA from 'react-ga';
import uuid from "uuid/v4";

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {getParams} from "./constants";

function App() {
    let path = window.location.href;
    const params = getParams(path);
    let companyId = params == null || params["id"] == null ? "someone_" + uuid() : params["id"];
    const prevCompanyId = window.localStorage.getItem("id");
    if(prevCompanyId != null && prevCompanyId !== "not_trackable" && companyId.startsWith("someone_")) {
        companyId = prevCompanyId;
    }
    window.localStorage.setItem("id", companyId);
    firebase.analytics().setUserId(companyId);
    ReactGA.initialize('UA-145928376-4');
    path = path.substring(0, path.indexOf("?") >= 0 ? path.indexOf("?") : path.length);
    if (path !== window.location.href) {
        window.history.replaceState({}, "", path);
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Index}/>
                <Route path="/projects" component={Projects}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/resume" component={Resume}/>
                <Route component={NotFound} status={404}/>
            </Switch>
        </Router>
    );
}

export default App;
